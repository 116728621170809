import Cheers from '../../images/cheers.jpg'
import Bottega from '../../images/bottega.jpeg'
import Gucci from '../../images/gucci.jpeg'
import Guerrucci from '../../images/gretailorguerucci.jpg'
import FightClub from '../../images/fightclub.jpeg'
import Amazon from '../../images/amazon.jpg'
import Recommendations from '../../images/Untitled-1.jpg'
import Instagram from '../../images/instagram.jpg'
import BigData from '../../images/bigdata.jpg'
import Facebook from '../../images/facebook.png'


export type ArticleType = {
  image: string,
  author: string,
  date: Date,
  title: string,
  content: string
}

export const posts:ArticleType[] = [{
  image: Guerrucci,
  title: "G.Retail OR GUERRUCCI?",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `## The Ones who stand out
  As brands move away from traditional advertising towards storytelling to build their image, the big question these days is how to get AND keep their audience’s attention. Therefore, we have done some data crunching on attention levels for some big brands who were best in class last year (and in some cases for years in a row) regarding innovative ways to position themselves.
  In general we see three big themes around which leading brands are working to win client’s hearts and souls. They all, obviously, get the basics right. Their agencies and internal teams master Social Media, SEO, SEA, and all the marketing basics better than anyone. So what are the three themes they turn to get an edge on competition? Firstly, be on the right side of history on all major issues which are relevant or important to their clients. Secondly, come up with new ways to present your story, off the beaten tracks. Thirdly, look for initiatives which help to create a true feeling of community among clients and fans (in the prime segment, young people may not be able to afford the actual products, but can still be fans and followers, hence contributing to the brand image).
  
  ## … And the winner is
  In our view, one brand nailed it on all those aspects – and more. Gucci came, since this summer, with an avalanche of new ideas which really hit the right strings with (potential) clients all over the world. They managed to bring their products in the spotlight through a modern form of art (short-film), together with very relevant influencers. On top of that, they successfully leveraged TikTok’s popularity. And as the cherry on the cake, they partnered with The North Face – which, since they were the first big brand to boycott Facebook last summer, is seen as one of the most socially responsible players in the fashion industry.
  
  ## The world isn’t flat
  What is interesting to see, is how all of those initiatives trigger different reactions in different parts of the world! We looked at Google search data for the Gucci brand, and rebased the number of searches on the start of the period we looked at – so the movements on the graph (see bottom) are rather in relative than absolute terms. What we notice is for example that in the US, the launch of Gucci Fest really led to a steep rise in interest. Gucci Fest consisted of a number of short films featuring Gucci products, starring a number of well-known music stars such as Harry Styles and Billie Eilish. We see that this type of content was very sought after in the US and Canada – and also the UK, while it triggered only limited enthusiasm in continental Europe. On the graph here, we have compared the US and Italy (Gucci’s roots), and one sees, how this movie series dramatically drove the interest for the Gucci brand higher in the US.
  
  The second thing we notice on this graph, is the immense success of the “Gucci Model Challenge” in Italy. TikTok influencers had created Gucci-looks from clothes in their closet, as a kind of parody. However, Gucci right away surfed along on this wave, and turned it into a marketing stunt of its own. Here again, we have compared searches for Gucci in the US with Italy, and we see that the TikTok effect is huge in Italy.
  
  ## Food for thought
  What does all this tell us? Well, obviously Gucci are marketing geniuses. But also that even the most brilliant marketing actions are very dependent on the audience, and not every trick works similarly well everywhere. A lot of food for thought…
  
  ![Gucci](${Gucci})
  `
},{
  image: FightClub,
  title: "THE FIRST RULE OF FIGHT CLUB… AND BOTTEGA VENETA",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `The first rule of Fight Club is not to speak about Fight Club. There is definitely a strong desire for people to be part of something which operates under the radar, and has some flavor of clandestineness over it. So when Bottega Veneta decided early in January to leave most social media – including Instagram, Twitter and Facebook – their strategy reminded us a lot of Tyler Durden’s words (coming from the mouth of Brad Pitt).

  Even if Bottega Veneta’s chief designer Daniel Lee probably didn’t have Fight Club in mind when he made the decision to get (temporarily?) out of the major social media channels, the resemblance between a photo on which he figured and the most famous picture from the FC movie can’t be a coincidence.
  
  ## Can anyone pull this off?
  Now, when this decision was taken, a big part of the fashion press was very surprised, as brands were using those during the pandemic as the primary way to keep in touch with their clients. So we worked out under what circumstances it would be a wise decision for Bottega Veneta to make such a move.
  
  First of all, as we tend to do in our blogs, we turn to Google to see how the public’s attention for the brand evolved since that decision. On the graph below, we see that the trend for Google searches on the Bottega brand was growing strongly over the past 5 years, but it hit a high exactly the week where the withdrawal from social media was announced, and has remained at elevated levels ever since.

  ![Bottega Venetta](${Bottega})
  
  ## Should Bottega have pulled its punches?
  Does that mean any brand could pull off such a move and still remain in the picture? NO! Ok, but what exactly then makes the difference between brands who can afford to ditch social media (at least for a while), and the ones who can’t? Is it a mere question of size or reliance on other marketing channels? Partly. In our view, there are 3 elements which the Bottega Veneta people looked at to see whether they could pull this off:
  
  1. The segment they are in: With the price tags for a typical BV piece of clothing or bag, they aim at clients who are not typical social media brand followers. Those are people though who would be sensitive to what influencers or people they know wear on those media. And of course, it is not because a brand doesn’t promote content actively on IG, that they can’t still use influencers.
  1. The ability to reach clients personally. We assume that Bottega Veneta can achieve hyper-personalized targeting and communication to their clients and prospects. If we would be working for them (which we don’t), we would advise to set up a system whereby a recommendation algo picks out pieces for their clients, which then can be contacted individually by e-mail, WhatsApp, text, or an old-fashioned letter.
  1. We also assume that Bottega Veneta made a careful analysis of its client structure. This is where cohort analysis and churn prediction come in. A company which has a decent history can relatively easily predict the loyalty of existing customers. If the forecasted churn in important cohorts is low, this means that the business is solid enough to thrive for a while, even without a lot of marketing support. If in addition, they were able to figure out that instream in new cohorts is pretty steady, and not per se correlated with social media engagement, that means they can afford to try something new!

  ## Bold ideas backed by careful analysis
  This is the great thing about combining hard data science with pure creativity. For every bold new idea, one can work out what the potential impact can be – and in some cases, the analysis can be used to strengthen the execution of the ideas.
  
  In the end, a brand tries to increase its relevance for their clients. Bottega Veneta projects itself as a brand whose customers don’t need social media to make their choices. They are able to connect with clients on an individual level. The fact that they chose to stay away from Instagram, Facebook and Twitter makes them stand out. Just like the Fight Club idea in the movie, the “secretive” (everything is relative of course) path it chose gives the brand an extra appeal. We don’t know how the sales figures of Bottega are evolving, but if the search activity on Google gives us any clue, they are on the right track.
  
  Bold ideas are great. Using data to back them up is even better. Food for thought….`
},{
  image: Amazon,
  title: "WHAT WE ALL CAN AND SHOULD LEARN FROM AMAZON",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `The biggest news of the past week in business was no major acquisition or IPO, a market moving economic indicator or the launch of a spectacular new product. No, it was just the decision of one individual to take a step back at the company he founded and managed over the past 25 years. His name is Jeff Bezos. And whether you are a fan of Amazon or not, everyone will agree that he was one of the most visionary businessmen of the past decades.

  The central idea to the way he built his business over the years is very simple, yet brilliant… ***Always put the customer first.*** Over the years, many companies also started calling themselves customer-centric, but very few truly walk that talk.
  
  ## Most companies don’t know their clients particularly well…
  There are many reasons why this is the case. Many general managers have a background in engineering, finance or HR- rather than in sales. A lot of companies have become great thanks to their focus on excellent products or efficient operations. All that is true…. But the main reason is: MOST COMPANIES DON’T KNOW THEIR CLIENTS PARTICULARLY WELL! And if you don’t know your clients, it is hard to make them the centre of your thinking.
  
  This is especially true for retailers or DTC brands…. If you have 30 clients, it is well manageable to know the preferences of your clients. Whether they prefer Italian or Chinese food. Whether they cycle or play music in their free time, etc. When you have thousands, or millions, of clients it becomes harder to know them.
  
  ## Do you know the answers to these questions?
  What kind of dress does client 407895 prefer? Why did client 200332 abruptly stop buying from us last year? Do I need to offer a discount to client 19824, or doesn’t she care?
  
  Amazon made it their business to know this. Other great companies like Netflix or Spotify followed, much to the delight of their shareholders.
  
  The good news is, most of the techniques those guys use, are within reach for most companies. Use your data. Put your data at work. And get to know your clients!`
},{
  image: Cheers,
  title: "CX STANDS FOR CHEERS EXPERIENCE",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `People having a bit of grey hair, or younger ones who like classic TV-shows may remember “Cheers”. The setting of this sitcom was a bar in Boston with the same name, and “Cheers” over the years would become one of the most successful TV-series of all time.

  ## Where everybody knows your name
  One of the most recognizable features of the show was the opening song – “Where everybody knows your name”. The success of the bar (and the show) was for a good part linked to the fact that it was a place where all the regulars felt at home. The “everybody knows your name” promise was put in evidence every time Norm Peterson (played by George Wendt) would walk in, and the whole crowd would yell: “Noooorrrrrmmmmm!!!” while he made his way to his regular seat at the bar.
  
  ## Create Client intimacy
  If this show proved one thing, it was how strong people are attracted to people, places and businesses which give them a feeling of belonging. People just love it when they walk into a place, and you are welcomed as if you are an old friend. You can walk into a place and be greeted by a very friendly member of staff, who smiles politely and is at your service and goes out of her/his way to meet all your wishes. But even this kind of great service will still not beat the experience of being greeted by someone who not only knows your name, but also knows your preferences, what you dislike, etc. In Cheers, Sam, Diane and Woody didn’t just know the names of their clients, but also what their favorite spot at the bar was, which drink they preferred, etc. This means growing from client service to client intimacy. The relationship with your client evolves from a transactional to partnership.
  
  ## CX on steroids
  One could say that Cheers was CX on steroids even long before this became a popular marketing theme. This way of doing business is exactly what G.Retail is all about. How can you give your clients – even if you only communicate with them through e-mail and social media – this same feeling? The answer is that you have all the necessary input to get to know them at a very deep level at your fingertips. We can help you extract information about your client, and convert those into marketing actions which will give every client of yours the feeling Mr. Peterson had when walking into Cheers, and hear everybody call out “Nooooooorrrrrrrmmmmm!!!!!”.`
}, {
  image: Recommendations,
  title: "WHY RECOMMENDATION SYSTEMS MATTER MORE THAN EVER!",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `One of the most inspiring ideas we encountered during the Covid-period was the concept of the “Changing Bucket list”, which we heard about for the first time through Steven Van Belleghem. The idea is that people start to size down their bucket list items due to restrictions on travel, events, business gatherings, etc.

  At G.Retail, we work with fashion brands and retailers – and this “bucket list“ -idea can be remarkably well applied to this sector. The most prominent example is the Suit…. People buy it to attend important meetings, go to weddings or fancy cocktail receptions. Nowadays, people wear a T-shirt in Teams or Zoom meetings, weddings have become small and informal gatherings and I only vaguely remember my last cocktail reception.
  
  Now men’s clothing brands can use Cohort Analysis to estimate the number of clients which will stop buying a specific good or service (a suit in this case). Cohort analysis is a well-known statistical technique which doesn’t require massive amounts of calculations, and can be implemented fairly easily. It is a behavioral analytics tool which breaks down your customers in groups and follows their evolution over time. The way in which the “first” groups evolve, then gives an indication on what to expect for the following cohorts.
  
  However, now you know the projected decrease in turn-over in one category in goods…. But what you really would like to know is how to convert these clients into buying other categories. The answer lies in the combination of the cohort analysis with a recommendation model. Several types of models exist, and at G.Retail we use an algorithm which looks like the original Amazon recommendation system to define client and product similarity. If you apply this to your clients who disappeared from former cohorts, you have a pretty good idea where to lead your clients who will go for a more casual style next!
  
  And even better…. When wearing a suit becomes more popular again, you can work your way back using the same method!`
},
{
  image: Instagram,
  title: "WHAT DOES MATHS HAVE TO DO WITH YOUR ENGAGEMENT LEVEL ON INSTAGRAM?",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `Everyone who regularly posts on social media gets a feeling for what kind of content will lead to lots of “likes”, “shares” or “comments”, and what on the contrary will lead to poorer levels of engagement. At G.Retail, we were wondering whether we would be able to predict engagement on Instagram using a mathematical formula. To test this, we looked at Instagram posts of a small boutique specialising in fashion for kids and teenagers between 6 and 18 y/o (Leon-10).

  For each post, we gave an “engagement score”, where a like would give 1 point, and a comment would increase the score by 3 points. We then gave each post a score on a number of features (for those wondering why some lines are highlighted, continue reading 😉):
  • ***Are there people in the picture, and if so, we gave different scores whether it was an adult, a kid/teen, multiple kids, ….***
  • ***Are the people on the picture known to the audience? Are the faces familiar?***
  • The day of the week where it was posted
  • The time of the day it was posted
  • If there was a picture of people, where it just people standing, were they in movement, did they assume a funny pose, …
  • If the post didn’t show any people, was it a picture, just text against a background, ….
  • ***What type of content was central in the post: Showing a product, announcing an event, news about the shop, announcing discounts, ….***
  • Was the picture taken inside or outside?
  • ***How different was the post from the last 3 posts? This is a pretty complex one, we will come back on it!***
  
  After running a number of tests, we dropped most of the variables we looked at and remained with the ones highlighted in ***bold/italic***. We performed a number of regression analyses, and found that we can indeed model the level of engagement pretty well with those 4 variables.
  
  Testing the Instagram activity over a period of about 4 months, we saw that the formula could explain 66% of the engagement (which is not perfect, but not bad), with extremely high statistical confidence (p-values for individual variables and F-value for the regression all below .025, and most of them even well below .01.
  For the non-geeks among us, that means that we are pretty sure the relationships we found are solid, and those 4 relationships explain about 2/3rd’s of the success of any post, leaving 1/3rd to chance OR to other factors. It is also interesting to reflect on which ones of the other variables didn’t have a significant effect on the levels of engagement. For example, we had expected an important impact on the timing of the posts. In terms of time during the day it was posted, this may be because most posts were in any case done at pretty similar timings (around 10 in the morning, or early afternoon), so there was no real effect. In terms of which day of the week it was posted, there was a lot of variance, but there was no impact on engagement.
  It is important to note that this doesn’t mean there can be no impact for other types of businesses…. The results depend on the type of business, the audience of IG-followers, …
  
  Conclusion for Leon-10 is that you want to show images of people, and if possible people familiar to the audience. In case of a small business, this often means images of the shop owners, their families or staff. Funnily, we got confirmation on this from comments by several small retailers, who during Covid created their own content, and had great client feed-back. The type of content is also extremely important, with contests, event announcements, etc. leading to results which beat discount announcements hands down.
  Lastly, we saw a very significant effect coming from the degree of “originality” compared to former posts. We compared each time for every feature whether it was different from the last 3 posts, and averaged those differences. The value we obtained this way, was also a very significant factor in explaining the success of a post. However, it also means that in order to achieve diversification, you can not stick to the same recipe of most succesful post characteristics. In other words, running a contest, with a fun gama-element in it will lead to lots of positive engagement, but if you post only this, every week, people will get bored as well.
  
  In a following post, we will compare results with Facebook-engagement for the same content to see how different audiences impact the engagement!`
}, {
  image: BigData,
  title: "BRANTANO AND SHOE RETAILERS IN BELGIUM – SOME QUICK DATA CRUNCHING….",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `A storm is going through the Belgian retail landscape as the FNG group is being split up and sold off in pieces. Shortly after it was anounced that the group above the JBC fashion chain (Claes Retail Group) would buy the Fred&Ginger and CKS brands and a good part of the CKS-stores, it now emerged that the German shoe-empire Deichmann will buy Brantano (although with a much smaller number of stores remaining, down to 43 from 109).

  The Brantano shops will be transformed to blend into the vanHaren brand, under which Deichmann operates in The Netherlands. In the past week, Brantano made headlines in Belgium as their liquidation sales, with discounts up to 75%, drew huge amounts of clients, leading to long waiting lines and even misbehaviour of some overly eager shoppers. In a few cities, the police was even forced to order Brantano shops to close as the safety of shoppers and staff couldn’t be guaranteed due to the big crowds.
  
  In the Belgian retail landscape, there were 3 prominent chains of shoe shops. Next to Brantano, there are Torfs and Bristol, both family-owned companies with a long tradition. Both will face the challenge of having to compete now with a Deichmann, which can leverage its size to put pressure on the two remaining local players. Now, how has interest for those three brands evolved over the past year?
  
  If we take Google Trends as a good proxy for overall interest from the general public, before August of this year, Brantano and Torfs were pretty similar in terms of search interest. Bristol, which is a bit less of a household name, generates on average about 2/3’s of the interest the others have. If anything, before Brantano hit the headlines due to the financial problems at the parent company, Torfs was on average slightly more trending. Also, regarding the patterns and evolutions of searches in Google,Torfs and Brantano are close to each other. Searches for the two keywords display a correlation of about 70% – depending on the period slightly more or less, but always in this order of magnitude. With Bristol, the correlation is still present, but to a much smaller extent, with correlations between 30 and 40%.
  
  It comes as no surprise that over the past weeks, with the headlines around mega-discounts, interest in Brantano has exploded, up to 10x higher than the average over the past year. But what we see is that also Torfs has profited from this, with search interest more than double the average, with the interest level at a year high. For Bristol, the interest is at the maximum for the year as well, at about 55% higher than usual. So, even if they face heavy competition in the future, both Torfs and (to a lesser extent) Bristol get some tailwinds for their brand awareness from this increased interest for the shoe retailing business as a whole…. Will be interesting to follow how this will evolve once we see the vanHaren brand popping up in Belgium…. To be continued!`
}, {
  image: Facebook,
  title: "THE DILEMMA FOR THE PRISONERS OF FACEBOOK",
  author: "Ivo Mertens",
  date: new Date(2021,1,1),
  content: `A few weeks ago, an interesting article appeared in FT, titled “Inside the exclusive club of Facebook advertisers”, featuring an interesting graph of which companies were brave enough to sideline with those who chose to boycott Facebook paying ads, in an attempt to force Zuckerberg & co to adhere to a certain degree of moral standards on the platform. This post is not about the moral side of this discussion, and isn’t aimed at separating the “good” from the “bad” advertisers. But the article got me digging into the reason why companies may choose to boycott while others remain faithfull to Facebook.

  First of all, obviously Facebook has some clever tactics in place to court CMO’s and CEO’s of some of the biggest advertisers. They stay close to their hearts and minds (and wallets) with a mix of classical charm methods, executed to perfection. This makes it difficult for any executive to turn her/his back on FB. On the other side of things, there is the will to do good, by taking a stand against FB’s lack of ethical standards. But there is another question. Who can actually afford to stop using FB (and IG) for paid ads. We have looked into the ROI estimations for various sectors, and the drivers of revenue through social media advertising.
  
  Basically, if we want to find out which companies are more likely to stop putting up with
  Facebook ethics, we need to compare the benefits of the good press which comes with doing so in public versus the cost. This cost depends largely on 2 factors. The more commoditised the product is, the more need for affordable marketing channels. Secondly, for sectors where it is important to show personalised offerings to (potential) clients, there are not much alternatives for FB and/or IG. So for companies who score high on both aspects, walking away from FB could come at a very high cost.
  
  Guess what. No bank has turned against Facebook yet. They are playing a multiplayer prisoner’s dilemma game where the pay offs are such that deviating from the current strategy first can cost them dearly.
  
  Are the executives of the companies putting pressure on Facebook doing the right thing? Absolutely. Will their decision benefit their firms in the long run? Likely. Are they morally superior? Hmmmm… it is easier to do good if you can afford it! Which explains also why there are a few exceptions on the reasoning for their sector. On the graph above we have plotted sectors according to commoditisation and need for personalisation. The size of the bubble measures the monetary value of a client/prospect. Obviously, all of this is not exact science and subject to a lot of assumptions, but we are looking for the larger picture here.
  
  The sectors on the left hand side of the orange line would find it easier to boycot FB. For premium CPG brands this was indeed the case, with many of the companies in case indeed belonging to this sector (Unilever, Coca-Cola, Hershey, …). For other sectors, more on the edge of the line, we have seen a smaller proportion of players joining the action – in the autosector only Honda and Ford, Target in the general retail, Microsoft in software. Obviously,it also helps if you are Microsoft and you are strong enough to do basically whatever you like…. Which is also a company which is led by people with a very strong sense of responsible citizenship.
  
  For the companies on the right hand side of the line, the situation is very comparable to a multiple-player prisoner’s dilemma. The pay-offs are such that it doesn’t make sense for anyone be the first to step up. In Financial Services for example, the landscape is incredibly competitive, and stepping away from FB gives an advantage to the competition which can’t be made up for in other ways. However, should a few players join the boycot anyway, others then could follow rapidly. But so far, for the sectors on the right, it doesn’t seem anyone is blinking their eyes yet.
  `
}]