import { useParams } from "react-router-dom"
import{ posts } from '../../lib/blog-posts/posts'
import ReactMarkdown from 'react-markdown'

import './styles.css'

const Article = () => {
  let { slug } = useParams<{slug?: string}>()
  const article = posts[parseInt(slug!)]

  return (
    <div className="article">
      <h1>{article.title}</h1>
      <p className="author-date">Author: {article.author} - {article.date.toDateString()}</p>
      <img src={article.image} alt="" className="featured-image"/>      
      <ReactMarkdown children={article.content} />
    </div>
  )
}

export default Article