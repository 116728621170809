import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import { Fade, AttentionSeeker } from 'react-awesome-reveal'
import { HashLink } from 'react-router-hash-link'

import TeamCard from '../../components/TeamCard'
import ContactForm from '../../components/ContactForm'

import VisibilityIcon from '@material-ui/icons/Visibility'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
// import Microsoft from '../../images/microsoft.png'
// import Ikea from '../../images/ikea.png'
// import Tesla from '../../images/tesla.png'
// import Sony from '../../images/sony.png'
import Ivo from '../../images/ivo.jpg'
import Koen from '../../images/koen.jpg'
// import Marie from '../../images/marie.png'
import Computer from '../../images/computer.png'
import Screen from '../../images/gretail_mission.png'
import './styles.css'

const Home = () => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open);
  }
  return (
    <div className="home">
      <div className="section splash" id="home">
        <Fade direction='down' triggerOnce>
          <div className="text">
            <h1>We predict demand and detect trends in your market. Grow your business with your data!​</h1>
            <Button component={HashLink} to="#about">Learn More</Button>
          </div>
        </Fade>
        <img src={Computer} alt="" className="img"/>
      </div>
      <div className="selling-points" id="about">
        <Fade direction='down' triggerOnce fraction={.8}>
          <h1>You know your business and your clients.<br/> You could sell more by getting to know them even better.</h1>
        </Fade>
        <Fade direction='down' triggerOnce cascade delay={500} duration={750}>
          <div className="selling-point"><div className="icon"><TrendingUpIcon /></div><h2>We help you to predict demand and detect trends in your market.</h2></div>
          <div className="selling-point"><div className="icon"><VisibilityIcon /></div><h2>We analyse your sales data to the smallest detail, and give you handy and practical recommendations to improve and grow your sales.</h2></div>
          <div className="selling-point"><div className="icon"><EmojiObjectsIcon /></div><h2>We give input to develop new products and to rightsize your inventory. Decrease the cost of unsold items and avoid lost sales at the same time.</h2></div>
        </Fade>
      </div>
      <div className="mission" id="mission">
        <div className="text">
          <img src={Screen} alt="papers"/>
          <div>
          <h1>Our Mission</h1>
            <p>Our mission is to help accelerate your growth. Knowing your clients is one of the biggest assets your company has. We transform this data into actionable suggestions which you can use to reach your clients. Based on your data, our algorithm and wizzkids, we predict demand and detect trends in your market.</p>
            <p>
            In your data we look for clues to answer the question: What would the world miss if your brand didn’t exist?
            </p>
          </div>
        </div>
      </div>
      <div className="team" id="team">
        <h1>Founders</h1>
        <p>
          G.Retail was founded by us, Koen Fruyt and Ivo Mertens. We have a background in investment banking and technology, while both our life partners are each active in the fashion retail business. That is how we discovered that the type of quantitative analysis which is used in the financial markets has not found its way into the retail world. Except for very large brands and platforms, data analytics is often limited to very basic and superficial outcomes. Smaller brands also have a wealth of information available to them, and we help you to access this knowledge. That is how G.Retail, where the G stands for Growth, was born and our mission remains to accelerate that growth with your data.
        </p>
        <div className="team-cards">
          <TeamCard name='Ivo Mertens' linkedin="https://www.linkedin.cn/in/ivo-mertens-5476a38" image={Ivo} aboutText={<p>Ivo spent 20 years working in the Global Markets departments of several banks in sales management roles. This where he gained expertise in sales techniques. In the last years, he was involved in a number of start-ups and scale-ups, helping them with their business development. After realising that most advanced quantitative and qualitative sales techniques are only within reach for very large players, the idea to start G.Retail was born. When not at work, he likes to spend his time cooking, cycling or boxing.</p>} />
          <TeamCard name='Koen Fruyt' linkedin="https://fr.linkedin.com/in/koen-fruyt-a5a2414" image={Koen} aboutText={<div><p>Koen is an investor and non-executive advisor at G.Retail. He spent most of his professional life in digital leadership roles in Technology, Operations and Digital Banking in the Financial Industry. Koen holds a Master Degree of Engineering and Corporate Finance from the KU Leuven and an Executive Master in Business and Administration from INSEAD. In his free time, Koen is an avid cyclist.</p></div>}/>
        </div>
      </div>
      {/*
      <div className="clients">
        <div className="logos">
          <img src={Sony} alt="" className="company-logo"/>
          <img src={Ikea} style={{ height: "4rem" }} alt="" className="company-logo"/>
          <img src={Microsoft} alt="" className="company-logo"/>
          <img src={Tesla} alt="" className="company-logo"/>
        </div>
      </div>*/}
      <div className="contact" id="contact">
        <AttentionSeeker effect='tada'>
          <h1>No cure? No pay!</h1>
        </AttentionSeeker>
        <h2>We understand that you are not convinced right away. But we are convinced that we help accelerate your growth. That is why we offer you a free trial so you can get to know us better see the results for yourself. Who are your star clients? Who is at risk? Who left the checkout but is not lost? Who are the early adopters and how to learn from them? Answers to those questions are vital and we will answer them for you. Get in touch by filling out the form below and we’ll set you up with one of our experts.</h2>
        <Button
            component={HashLink}
            to="#footer"
            onClick={handleClick}
            aria-expanded={open}
            aria-label="show contact form"
          >
            I'll take that deal!
        </Button>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ContactForm />
        </Collapse>
      </div>
      <div className="contact-info" id="contact-info">
          <h1>Want to say hi or ask us anything? Feel free to email us at <a href="mailto:hello@gretail.io">hello@gretail.io</a> or give us a call at +32 (0) 499 674 714. <br />We’re looking forward to get to know you.</h1>
          <div className="">
          <p>G.Retail</p>
          <p><em>"Grow your business with your data!​“</em></p><br />
          <p>+32 (0) 499 674 714</p>
          <p><a href="mailto:hello@gretail.io">hello@gretail.io</a></p>
          </div>
      </div>
    </div>
  )
}

export default Home