import { useState } from 'react'
import { cases } from '../../lib/cases'
import CaseCard from '../../components/CaseCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import 'swiper/swiper-bundle.min.css'
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import "swiper/components/pagination/pagination.min.css"
import './styles.css'
import ReactMarkdown from 'react-markdown'

SwiperCore.use([EffectCoverflow, Pagination]);

const Case = () => {
  const [article, setArticle] = useState(cases[1])

  const mobile = useMediaQuery('(max-width:768px)')
  return (
    <div className="case">
        <h1 className="title">Cases and Tools</h1>
      <div className="coverflow-container" style={{marginBottom: '5rem'}}>
        <Swiper
          spaceBetween={0}
          slidesPerView={mobile ? 1.1 : 3}
          grabCursor={true}
          centeredSlides={true}
          initialSlide={1}
          watchSlidesVisibility={true}
          pagination={{clickable: true}}
          effect="coverflow"
          onSlideChange={(swiper) => {
            setArticle(cases[swiper.activeIndex]);
          }}
          coverflowEffect={{rotate: 30, slideShadows: true, stretch: 0, depth: 100, modifier: 1}}
        >
          {cases.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <CaseCard data={item} index={index} />
              </SwiperSlide>
            )
          })}
      </Swiper>    
      </div>
      <div className="case-article">
        <h1>{article.title}</h1>
        <ReactMarkdown children={article.content} />
      </div>
    </div>
  )
}

export default Case