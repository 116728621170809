import Cluster from '../images/cluster.jpg'
import Cohort from '../images/cohort.png'
import Recommendation from '../images/recommendation.jpg'
import Social from '../images/social.jpg'

export type CaseType = {
  image: string,
  title: string,
  company: string,
  content: string
}

export const cases: CaseType[] = [
  {
    image: Cluster,
    title: "Gain insight to your client types",
    company: "Cluster-Analysis and Client Insights",
    content: "For a large retailer in outdoor equipment and garden furniture, we analyzed carefully their client revenues. It appeared that the company had a much higher customer rotation than they had estimated. Every year, around 80% of turn-over is generated by clients who only became active with them during that specific year. G.Retail helped them to identify the sleeping clients, and work out what kind of propositions could bring them back. So the next step was to split their client base in clusters based on RFM (Recency/Frequency/Monetary Value)criteria. Then we singled out the clusters with the highest potential and based on product correlations, we made offers in the most relevant products for those clients. The end goal for any type of analysis is each time to find insights which lead to actionable results."
  },
  {
    image: Cohort,
    title: "Optimize brands in portfolio",
    company: "Cohort Analysis",
    content: "A chain of multi-brand fashion boutiques was struggling with the composition of their brand portfolio. Over the years, they added new brands regularly, but failed to ditch others. That way, they found themselves in a situation where they were selling a very wide range of brands, and had a difficult time to get an overview of the added value of each brand. We optimise your brand portfolio - taking into account potential overlaps between brands, prediction of future demand for the brands and client loyalties towards the various brands. \n G.Retail performed a cohort-analysis which gives a perfect view of the loyalty of your client base towards a given brand or even a specific kind of style. In addition, this type of analysis can help you to predict the demand for a specific brand or type of item in the following years. Given that this example is in the fashion industry, we also incorporated the seasonal effect, whereby certain brands tend to have more appeal in winter – versus summer-collections or vice versa.\n This analysis leads to better buying decisions for future seasons. The buyers of the retailer can make sure they purchase what their customers will want the following year, and at the same time decrease the risk of being left with too much stock at the end of the season."
  },
  {
    image: Recommendation,
    title: "Deep Recommendation Model",
    company: "Example Company – Fashion",
    content: "Our first client was a premium Belgian fashion brand, which also operates a number of flagship stores. They wanted to reach out to their clients in a hyper-personalized way. Many tools which claim to “personalize” any offering just use some rule-of-thumb methods, or simply present the “best-selling article”. We tailor-made an algorithm combining product-complementarity and client-similarity to enable them to produce for each and every existing client a list of the items in the current collection they were most likely to buy.\n The result was an increase in relevance in the consumer’s eyes, as they were offered only items which were directly in the line of what they like and would potentially buy."
  },
  {
    image: Social,
    title: "Increase return of investment",
    company: "Data-Driven Social Media Strategies",
    content: "For a web-shop which is internationally active, we identified the exact geographical “hotspots” where their turn-over was coming from. We compared those results with assumptions on the potential for their business in several regions. Based on the differences and overlaps between those two views, they were able to fine-tune their social media strategies on Facebook and Instagram, using micro-targeting. The aim always being to sell more products and drastically improve marketing ROI."
  }
]
