import { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';

import Header from './components/Header'
import Footer from './components/Footer'

import Article from './pages/Article'
import Blog from './pages/Blog'
import Case from './pages/Case'
import Home from './pages/Home'

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Header />
      <div className="background">
        <div className="wave" />
      </div>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/blog'>
          <Blog />
        </Route>
        <Route path='/article/:slug'>
          <Article />
        </Route>
        <Route path='/cases'>
          <Case />
        </Route>
      </Switch>
      <Footer />
    </Suspense>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
