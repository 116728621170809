import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { useFormControls } from "./ContactFormControls"

import './styles.css'

const inputFieldValues = [
  {
    name: "fullName",
    label: "Full Name",
    id: "my-name"
  },
  {
    name: "phone",
    label: "Phone",
    id: "my-phone"
  },
  {
    name: "company",
    label: "Company",
    id: "my-company"
  },
  {
    name: "email",
    label: "Email",
    id: "my-email"
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
    placeholder: "Do you have any questions or remarks you would like to share now?",
    multiline: true,
    rows: 10
  }
];  

const ContactForm = () => {
  const {
    values,
    handleInputValue,
    formIsValid,
    handleFormSubmit,
    errors
  } = useFormControls();

  return (values.formSubmitted ? (values.success? <p>Thank you for your message! We will contact you as soon as possible.</p> : <p>Something went wrong with your message, please try again later.</p>) :
    <form className="contact-form" autoComplete="off" onSubmit={handleFormSubmit}>
    {inputFieldValues.map((inputFieldValue, index) => {
      return (
        <TextField
          key={index}
          onChange={handleInputValue}
          onBlur={handleInputValue}
          name={inputFieldValue.name}
          label={inputFieldValue.label}
          error={errors[inputFieldValue.name]}
          multiline={inputFieldValue.multiline ?? false}
          fullWidth
          placeholder={inputFieldValue.placeholder}
          rows={inputFieldValue.rows ?? 1}
          autoComplete="none"
          {...(errors[inputFieldValue.name] && {
            error: true,
            helperText: errors[inputFieldValue.name]
          })}
        />
      );
    })}
    <Button
      variant="contained"
      type="submit"
      color="secondary"
      disabled={!formIsValid()}
    >
      Send Message
    </Button>
  </form>
  )
}

export default ContactForm