import { ReactElement, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import './styles.css'

type TeamCardProps = {
  image: string,
  name: string,
  aboutText: ReactElement,
  linkedin: string
}

const TeamCard = ({image, name, aboutText, linkedin}: TeamCardProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="team-card">
      <Card>
        <CardMedia
          className="media"
          image={image}
          title={name}
        />
        <CardActions>
          <h3>
            {name}
          </h3>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {aboutText}
          <IconButton
            href={linkedin}
            target="_blank"
            className="linkedin"
          >
            <LinkedInIcon />
          </IconButton>
        </CardContent>
      </Collapse>
      </Card>
    </div>
  )
}

export default TeamCard