import { CaseType } from '../../lib/cases'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import './styles.css'

type CaseProps = {
   data: CaseType,
   index: number
}

const CaseCard = ({ data, index }: CaseProps ) => {
  return (
    <div className="case-card">
      <Card>
        <a href={`/use-case/${index}`}>
          <CardMedia className="media" image={data.image}/>
        </a>
        <CardActions>
          <div className="case-title">
            <h3><strong>{data.title}</strong></h3>
            <h4>{data.company}</h4>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default CaseCard