import './styles.css'

const Footer = () => {
  const date = new Date();
  return (
    <div className="footer">
      <h2>&copy; {date.getFullYear()} G.Retail, all rights reserved - Grow your business with your data. | Powered by <a href="https://nakatori.agency" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>Nakatori</a></h2>
    </div>
  )
}

export default Footer