import { ArticleType } from '../../lib/blog-posts/posts'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'

import ReadMoreIcon from '@material-ui/icons/MenuBook'
import './styles.css'

type BlogProps = {
  data: ArticleType,
  index: number
}

const BlogCard = ({data, index}: BlogProps) => {
  return (
    <div className="blog-card">
      <Card>
        <a href={`/article/${index}`}>
          <CardMedia className="media" image={data.image}/>
        </a>
        <CardActions>
          <div className="case-title">
            <h3><strong>{data.title}</strong></h3>
          </div>
          <IconButton href={`/article/${index}`}>
            <ReadMoreIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  )
}

export default BlogCard