import { posts } from '../../lib/blog-posts/posts'
import BlogCard from '../../components/BlogCard'

import './styles.css'

const Blog = () => {
  return (
    <div className="blog">
      <div className="blog-posts">
        <div className="blog-title">
          <h1 className="title">G.Retail Blog</h1>
        </div>
        {posts.map((item, index) => {
          return (
            <BlogCard key={index} data={item} index={index}/>
          )
        })}
      </div>
    </div>
  )
}

export default Blog