import { useState } from 'react'
import { withRouter, RouterProps } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import LinkUI from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Button from "@material-ui/core/Button"
import IconButton from '@material-ui/core/IconButton'

import { HashLink } from 'react-router-hash-link'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuIcon from "@material-ui/icons/Menu"
import Logo from '../../images/gretail.png'
import './styles.css'

type LinkType = {
  title: string,
  slug: string,
  subItems?: LinkType[]
}

const Header = ({ history }: RouterProps) => {
  const [menu, setMenu] = useState(false)
  const [dropdown, setDropdown] = useState(false)

  const links: LinkType[] = [
    {title: "About", slug: "#about"},
    {title: "Mission", slug: "#mission"},
    {title: "Founders", slug: "#team"}, 
    {title: "Cases", slug: "cases"},
    {title: "Blog", slug: "blog"},
    {title: "Try us", slug: "#contact"},
    {title: "Contact", slug: "#contact-info"},
  ]

  const menuItems = () => {
    return (
      <div className="drawerMenu" role='presentation' onClick={() => setMenu(false)}>
        <List>
          {links.map((link, index) => {
            if (link.subItems) {
              return (
                <div key={link.title}>
                  <ListItem button  onClick={(e: React.MouseEvent<HTMLElement>) => {e.stopPropagation();setDropdown(!dropdown);}}>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
                    <ListItemText>{link.title}</ListItemText>
                    <ExpandMoreIcon className="dropdown" style={{ transform: dropdown ? 'rotate(90deg)' : ''}} />
                    </div>
                  </ListItem>
                  <Collapse in={dropdown} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                         {link.subItems.map((sublink, index) => {
                           return (
                              <ListItem button key={sublink.title} component={HashLink} to={`/${sublink.slug}`}>
                                <ListItemText primary={sublink.title} />
                              </ListItem>
                           )
                         })}
                        </List>
                    </Collapse>
                </div>
              )
            } else {
              return (
                <ListItem button key={link.title} onClick={() => setMenu(false)} component={HashLink} to={`/${link.slug}`}>
                  <ListItemText>{link.title}</ListItemText>
                </ListItem>
              )
            }
          })}
          <IconButton
            href="https://www.linkedin.com/company/guerretail/"
            target="_blank"
            className="linkedin"
          >
            <LinkedInIcon />
          </IconButton>

        </List>
      </div>
    )
  }

  return (
    <div className="header">
      <div className="nav-container">
        <a className="logo" href="/">
          <img src={Logo} alt="G.Retail Logo" />
        </a>
        <div className="links">
          {links.map((item: LinkType, index: number) => {
            return (
              <LinkUI key={index} color="inherit" underline="none" component={HashLink} to={`${item.slug}`} onClick={(e: React.MouseEvent<HTMLElement>) => {history.push(`/${item.slug}`)}}>
                {item.title}
              </LinkUI>
            )
          })}
          <IconButton
            href="https://www.linkedin.com/company/guerretail/"
            target="_blank"
            className="linkedin"
          >
            <LinkedInIcon />
          </IconButton>
        </div>
        <div className="burger-button">
          <Button onClick={() => setMenu(true)} aria-label="burger-menu">
            <MenuIcon className="menuButton"/>
          </Button>
        </div>
      </div>
      <Drawer anchor='right' open={menu} onClose={() => setMenu(false)}>  
          {menuItems()}
      </Drawer>
    </div>
  )
}

export default withRouter(Header)